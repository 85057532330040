export var MessageCode;
(function (MessageCode) {
    MessageCode[MessageCode["loginError"] = 0] = "loginError";
    MessageCode[MessageCode["noPermission"] = 1] = "noPermission";
    MessageCode[MessageCode["loadListFailed"] = 2] = "loadListFailed";
    MessageCode[MessageCode["getDetailFailed"] = 3] = "getDetailFailed";
    MessageCode[MessageCode["createWorkspaceFailed"] = 4] = "createWorkspaceFailed";
    MessageCode[MessageCode["getMembersFailed"] = 5] = "getMembersFailed";
    MessageCode[MessageCode["updateWorkspaceFailed"] = 6] = "updateWorkspaceFailed";
    MessageCode[MessageCode["deleteWorkspaceFailed"] = 7] = "deleteWorkspaceFailed";
    MessageCode[MessageCode["inviteMemberFailed"] = 8] = "inviteMemberFailed";
    MessageCode[MessageCode["removeMemberFailed"] = 9] = "removeMemberFailed";
    MessageCode[MessageCode["acceptInvitingFailed"] = 10] = "acceptInvitingFailed";
    MessageCode[MessageCode["getBlobFailed"] = 11] = "getBlobFailed";
    MessageCode[MessageCode["leaveWorkspaceFailed"] = 12] = "leaveWorkspaceFailed";
    MessageCode[MessageCode["downloadWorkspaceFailed"] = 13] = "downloadWorkspaceFailed";
})(MessageCode || (MessageCode = {}));
export const messages = {
    [MessageCode.loginError]: {
        message: 'Login failed',
    },
    [MessageCode.noPermission]: {
        message: 'No permission',
    },
    [MessageCode.loadListFailed]: {
        message: 'Load list failed',
    },
    [MessageCode.getDetailFailed]: {
        message: 'Get detail failed',
    },
    [MessageCode.createWorkspaceFailed]: {
        message: 'Create workspace failed',
    },
    [MessageCode.getMembersFailed]: {
        message: 'Get members failed',
    },
    [MessageCode.updateWorkspaceFailed]: {
        message: 'Update workspace failed',
    },
    [MessageCode.deleteWorkspaceFailed]: {
        message: 'Delete workspace failed',
    },
    [MessageCode.inviteMemberFailed]: {
        message: 'Invite member failed',
    },
    [MessageCode.removeMemberFailed]: {
        message: 'Remove member failed',
    },
    [MessageCode.acceptInvitingFailed]: {
        message: 'Accept inviting failed',
    },
    [MessageCode.getBlobFailed]: {
        message: 'Get blob failed',
    },
    [MessageCode.leaveWorkspaceFailed]: {
        message: 'Leave workspace failed',
    },
    [MessageCode.downloadWorkspaceFailed]: {
        message: 'Download workspace failed',
    },
};
