import { createStore, keys, setMany, getMany, clear } from 'idb-keyval';
import * as idb from 'lib0/indexeddb.js';
export function getDatabase(type, database) {
    const name = `${database}_${type}`;
    const db = createStore(name, type);
    return {
        keys: () => keys(db),
        clear: () => clear(db),
        deleteDB: () => idb.deleteDB(name),
        setMany: entries => setMany(entries, db),
        getMany: keys => getMany(keys, db),
    };
}
