const defaultLogger = () => {
    return;
};
export class BaseProvider {
    /** provider id */
    id = 'base';
    /** workspace unit collection */
    _workspaces;
    _logger;
    /** send message with message center */
    _sendMessage;
    constructor({ logger, workspaces, messageCenter, }) {
        this._logger = (logger || defaultLogger);
        this._workspaces = workspaces;
        this._sendMessage = messageCenter.getMessageSender(this.id);
    }
    /**
     * hook after provider registered
     */
    async init() {
        return;
    }
    /**
     * auth provider
     */
    async auth() {
        return;
    }
    /**
     * logout provider
     */
    async logout() {
        return;
    }
    getToken() {
        return '';
    }
    /**
     * warp workspace with provider functions
     * @param workspace
     * @returns
     */
    async warpWorkspace(workspace) {
        return workspace;
    }
    /**
     * @deprecated Temporary for public workspace
     * @param blocksuiteWorkspace
     * @returns
     */
    async loadPublicWorkspace(blocksuiteWorkspace) {
        return blocksuiteWorkspace;
    }
    /**
     * load workspaces
     **/
    async loadWorkspaces() {
        throw new Error(`provider: ${this.id} loadWorkSpace Not implemented`);
    }
    /**
     * get auth user info
     * @returns
     */
    async getUserInfo() {
        return;
    }
    // async getBlob(id: string): Promise<string | null> {
    //   return await this._blobs.get(id);
    // }
    // async setBlob(blob: Blob): Promise<string> {
    //   return await this._blobs.set(blob);
    // }
    /**
     * clear all local data in provider
     */
    async clear() {
        // this._blobs.clear();
    }
    /**
     * delete workspace include all data
     * @param id workspace id
     */
    async deleteWorkspace(id) {
        id;
        return;
    }
    /**
     * leave workspace by workspace id
     * @param id workspace id
     */
    async leaveWorkspace(id) {
        id;
        return;
    }
    /**
     * close db link and websocket connection and other resources
     * @param id workspace id
     */
    async closeWorkspace(id) {
        id;
        return;
    }
    /**
     * invite workspace member
     * @param id workspace id
     */
    async invite(id, email) {
        id;
        email;
        return;
    }
    /**
     * remove workspace member by permission id
     * @param permissionId
     */
    async removeMember(permissionId) {
        permissionId;
        return;
    }
    async publish(id, isPublish) {
        id;
        isPublish;
        return;
    }
    /**
     * change workspace meta by workspace id , work for cached list in different provider
     * @param id
     * @param meta
     * @returns
     */
    async updateWorkspaceMeta(id, params) {
        id;
        params;
        return;
    }
    /**
     * create workspace by workspace meta
     * @param {CreateWorkspaceInfoParams} meta
     */
    async createWorkspace(meta) {
        throw new Error(`provider: ${this.id} createWorkspace not implemented`);
    }
    async extendWorkspace(workspaceUnit) {
        throw new Error(`provider: ${this.id} extendWorkspace not implemented`);
    }
    /**
     * get user by email
     * @param {string} id
     * @param {string} email
     * @returns
     */
    async getUserByEmail(id, email) {
        email;
        return null;
    }
    /**
     * link workspace to local caches
     * @param workspace
     * @returns
     */
    async linkLocal(workspace) {
        return workspace;
    }
    /**
     * get workspace members
     * @param {string} workspaceId
     * @returns
     */
    getWorkspaceMembers(workspaceId) {
        workspaceId;
        return Promise.resolve([]);
    }
    /**
     * accept invitation
     * @param {string} inviteCode
     * @returns
     */
    async acceptInvitation(inviteCode) {
        inviteCode;
        return null;
    }
}
