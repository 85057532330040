import { MessageCenter } from '../../../message/index.js';
import { bareClient, client } from './request.js';
const messageCenter = MessageCenter.getInstance();
const sendMessage = messageCenter.getMessageSender('affine');
const { messageCode } = MessageCenter;
class RequestError extends Error {
    constructor(message, cause = null) {
        super(message);
        this.name = 'RequestError';
        this.cause = cause;
    }
}
export var WorkspaceType;
(function (WorkspaceType) {
    WorkspaceType[WorkspaceType["Private"] = 0] = "Private";
    WorkspaceType[WorkspaceType["Normal"] = 1] = "Normal";
})(WorkspaceType || (WorkspaceType = {}));
export var PermissionType;
(function (PermissionType) {
    PermissionType[PermissionType["Read"] = 0] = "Read";
    PermissionType[PermissionType["Write"] = 1] = "Write";
    PermissionType[PermissionType["Admin"] = 2] = "Admin";
    PermissionType[PermissionType["Owner"] = 99] = "Owner";
})(PermissionType || (PermissionType = {}));
export async function getWorkspaces() {
    try {
        return client
            .get('api/workspace', {
            headers: {
                'Cache-Control': 'no-cache',
            },
        })
            .json();
    }
    catch (error) {
        sendMessage(messageCode.loadListFailed);
        throw new RequestError('load list failed', error);
    }
}
export async function getWorkspaceDetail(params) {
    try {
        const response = client.get(`api/workspace/${params.id}`);
        return response.json();
    }
    catch (error) {
        sendMessage(messageCode.getDetailFailed);
        throw new RequestError('get detail failed', error);
    }
}
export async function getWorkspaceMembers(params) {
    try {
        return client.get(`api/workspace/${params.id}/permission`).json();
    }
    catch (error) {
        sendMessage(messageCode.getMembersFailed);
        throw new RequestError('get members failed', error);
    }
}
export async function createWorkspace(encodedYDoc) {
    try {
        return client.post('api/workspace', { body: encodedYDoc }).json();
    }
    catch (error) {
        sendMessage(messageCode.createWorkspaceFailed);
        throw new RequestError('create workspace failed', error);
    }
}
export async function updateWorkspace(params) {
    try {
        return client
            .post(`api/workspace/${params.id}`, {
            json: {
                public: params.public,
            },
        })
            .json();
    }
    catch (error) {
        sendMessage(messageCode.updateWorkspaceFailed);
        throw new RequestError('update workspace failed', error);
    }
}
export async function deleteWorkspace(params) {
    await client.delete(`api/workspace/${params.id}`).catch(error => {
        sendMessage(messageCode.deleteWorkspaceFailed);
        throw new RequestError('delete workspace failed', error);
    });
}
/**
 * Notice: Only support normal(contrast to private) workspace.
 */
export async function inviteMember(params) {
    try {
        return client
            .post(`api/workspace/${params.id}/permission`, {
            json: {
                email: params.email,
            },
        })
            .json();
    }
    catch (error) {
        sendMessage(messageCode.inviteMemberFailed);
        throw new RequestError('invite member failed', error);
    }
}
export async function removeMember(params) {
    await client.delete(`api/permission/${params.permissionId}`).catch(error => {
        sendMessage(messageCode.removeMemberFailed);
        throw new RequestError('remove member failed', error);
    });
}
export async function acceptInviting(params) {
    try {
        return bareClient.post(`api/invitation/${params.invitingCode}`).json();
    }
    catch (error) {
        sendMessage(messageCode.acceptInvitingFailed);
        throw new RequestError('accept inviting failed', error);
    }
}
export async function uploadBlob(params) {
    return client.put('api/blob', { body: params.blob }).text();
}
export async function getBlob(params) {
    try {
        return client.get(`api/blob/${params.blobId}`).arrayBuffer();
    }
    catch (error) {
        sendMessage(messageCode.getBlobFailed);
        throw new RequestError('get blob failed', error);
    }
}
export async function leaveWorkspace({ id }) {
    await client
        .delete(`api/workspace/${id}/permission`)
        .json()
        .catch(error => {
        sendMessage(messageCode.leaveWorkspaceFailed);
        throw new RequestError('leave workspace failed', error);
    });
}
export async function downloadWorkspace(workspaceId, published = false) {
    try {
        if (published) {
            return bareClient.get(`api/public/doc/${workspaceId}`).arrayBuffer();
        }
        return client.get(`api/workspace/${workspaceId}/doc`).arrayBuffer();
    }
    catch (error) {
        sendMessage(messageCode.downloadWorkspaceFailed);
        throw new RequestError('download workspace failed', error);
    }
}
