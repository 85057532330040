import { Observable } from 'lib0/observable';
export class WorkspaceUnitCollection {
    _events = new Observable();
    _workspaceUnitMap = new Map();
    get workspaces() {
        return Array.from(this._workspaceUnitMap.values());
    }
    on(type, callback) {
        this._events.on(type, callback);
    }
    once(type, callback) {
        this._events.once(type, callback);
    }
    find(workspaceId) {
        return this._workspaceUnitMap.get(workspaceId);
    }
    createScope() {
        const scopedWorkspaceIds = new Set();
        const get = (workspaceId) => {
            if (!scopedWorkspaceIds.has(workspaceId)) {
                return;
            }
            return this._workspaceUnitMap.get(workspaceId);
        };
        const add = (workspaceUnit) => {
            const workspaceUnits = Array.isArray(workspaceUnit)
                ? workspaceUnit
                : [workspaceUnit];
            let added = false;
            workspaceUnits.forEach(workspaceUnit => {
                if (this._workspaceUnitMap.has(workspaceUnit.id)) {
                    // FIXME: multiple add same workspace
                    return;
                }
                added = true;
                this._workspaceUnitMap.set(workspaceUnit.id, workspaceUnit);
                scopedWorkspaceIds.add(workspaceUnit.id);
            });
            if (!added) {
                return;
            }
            this._events.emit('change', [
                {
                    added: workspaceUnits,
                },
            ]);
        };
        const remove = (workspaceId, isUpdate = true) => {
            const workspaceIds = Array.isArray(workspaceId)
                ? workspaceId
                : [workspaceId];
            const workspaceUnits = [];
            workspaceIds.forEach(workspaceId => {
                if (!scopedWorkspaceIds.has(workspaceId)) {
                    return;
                }
                const workspaceUnit = this._workspaceUnitMap.get(workspaceId);
                if (workspaceUnit) {
                    const ret = this._workspaceUnitMap.delete(workspaceId);
                    // If deletion failed, return.
                    if (!ret) {
                        return;
                    }
                    workspaceUnits.push(workspaceUnit);
                    scopedWorkspaceIds.delete(workspaceId);
                }
            });
            if (!workspaceUnits.length) {
                return false;
            }
            if (isUpdate) {
                this._events.emit('change', [
                    {
                        deleted: workspaceUnits,
                    },
                ]);
            }
            return true;
        };
        const clear = (isUpdate = true) => {
            remove(Array.from(scopedWorkspaceIds), isUpdate);
        };
        const update = (workspaceId, meta) => {
            if (!scopedWorkspaceIds.has(workspaceId)) {
                return true;
            }
            const workspaceUnit = this._workspaceUnitMap.get(workspaceId);
            if (!workspaceUnit) {
                return true;
            }
            workspaceUnit.update(meta);
            this._events.emit('change', [
                {
                    updated: workspaceUnit,
                },
            ]);
        };
        // TODO: need to optimize
        const list = () => {
            const workspaceUnits = [];
            scopedWorkspaceIds.forEach(id => {
                const workspaceUnit = this._workspaceUnitMap.get(id);
                if (workspaceUnit) {
                    workspaceUnits.push(workspaceUnit);
                }
            });
            return workspaceUnits;
        };
        return {
            get,
            list,
            add,
            remove,
            clear,
            update,
        };
    }
}
