import { WorkspaceUnit } from '../../workspace-unit.js';
import { createBlocksuiteWorkspace } from '../../utils/index.js';
import { setDefaultAvatar } from '../utils.js';
import { applyUpdate } from '../../utils/index.js';
import { getDatabase } from './idb-kv.js';
export const loadWorkspaceUnit = async (params, apis) => {
    const workspaceUnit = new WorkspaceUnit(params);
    const blocksuiteWorkspace = createBlocksuiteWorkspace(workspaceUnit.id);
    const updates = await apis.downloadWorkspace(workspaceUnit.id, params.published);
    applyUpdate(blocksuiteWorkspace, new Uint8Array(updates));
    const details = await apis.getWorkspaceDetail({ id: workspaceUnit.id });
    const owner = details?.owner;
    workspaceUnit.setBlocksuiteWorkspace(blocksuiteWorkspace);
    workspaceUnit.update({
        name: blocksuiteWorkspace.meta.name,
        avatar: blocksuiteWorkspace.meta.avatar,
        memberCount: details?.member_count || 1,
        owner: owner
            ? {
                id: owner.id,
                name: owner.name,
                avatar: owner.avatar_url,
                email: owner.email,
            }
            : undefined,
    });
    return workspaceUnit;
};
export const createWorkspaceUnit = async (params) => {
    const workspaceUnit = new WorkspaceUnit(params);
    const blocksuiteWorkspace = createBlocksuiteWorkspace(workspaceUnit.id);
    blocksuiteWorkspace.meta.setName(workspaceUnit.name);
    if (!workspaceUnit.avatar) {
        await setDefaultAvatar(blocksuiteWorkspace);
        workspaceUnit.update({ avatar: blocksuiteWorkspace.meta.avatar });
    }
    workspaceUnit.setBlocksuiteWorkspace(blocksuiteWorkspace);
    return workspaceUnit;
};
export const migrateBlobDB = async (oldWorkspaceId, newWorkspaceId) => {
    const oldDB = getDatabase('blob', oldWorkspaceId);
    const oldPendingDB = getDatabase('pending', oldWorkspaceId);
    const newDB = getDatabase('blob', newWorkspaceId);
    const newPendingDB = getDatabase('pending', newWorkspaceId);
    const keys = await oldDB.keys();
    const values = await oldDB.getMany(keys);
    const entries = keys.map((key, index) => {
        return [key, values[index]];
    });
    await newDB.setMany(entries);
    const pendingEntries = entries.map(([id, blob]) => {
        return [id, { id, blob }];
    });
    await newPendingDB.setMany(pendingEntries);
    await oldDB.clear();
    await oldPendingDB.clear();
};
