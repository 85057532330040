import { Observable } from 'lib0/observable';
import { MessageCode, messages } from './code.js';
export class MessageCenter extends Observable {
    _messages = messages;
    constructor() {
        super();
    }
    static instance;
    static getInstance() {
        if (!MessageCenter.instance) {
            MessageCenter.instance = new MessageCenter();
        }
        return MessageCenter.instance;
    }
    static messageCode = MessageCode;
    getMessageSender(provider) {
        return this._send.bind(this, provider);
    }
    _send(provider, messageCode) {
        this.emit('message', [
            { ...this._messages[messageCode], provider, code: messageCode },
        ]);
    }
    onMessage(callback) {
        this.on('message', callback);
    }
}
