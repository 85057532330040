import { BaseProvider } from '../base.js';
import { varStorage as storage } from 'lib0/storage';
import { uuidv4 } from '@blocksuite/store';
import { IndexedDBProvider } from './indexeddb/indexeddb.js';
import { applyLocalUpdates } from './indexeddb/utils.js';
import assert from 'assert';
import { loadWorkspaceUnit, createWorkspaceUnit } from './utils.js';
const WORKSPACE_KEY = 'workspaces';
export class LocalProvider extends BaseProvider {
    id = 'local';
    _idbMap = new Map();
    constructor(params) {
        super(params);
    }
    _storeWorkspaces(workspaceUnits) {
        storage.setItem(WORKSPACE_KEY, JSON.stringify(workspaceUnits.map(w => {
            return w.toJSON();
        })));
    }
    async linkLocal(workspace) {
        assert(workspace.room);
        let idb = this._idbMap.get(workspace);
        if (!idb) {
            idb = new IndexedDBProvider(workspace.room, workspace.doc);
        }
        this._idbMap.set(workspace, idb);
        this._logger('Local data loaded');
        return workspace;
    }
    async warpWorkspace(workspace) {
        assert(workspace.room);
        await applyLocalUpdates(workspace);
        await this.linkLocal(workspace);
        return workspace;
    }
    async loadWorkspaces() {
        const workspaceStr = storage.getItem(WORKSPACE_KEY);
        if (workspaceStr) {
            try {
                const workspaceMetas = JSON.parse(workspaceStr);
                const workspaceUnits = await Promise.all(workspaceMetas.map(meta => {
                    return loadWorkspaceUnit(meta);
                }));
                this._workspaces.add(workspaceUnits);
                return workspaceUnits;
            }
            catch (error) {
                this._logger(`Failed to parse workspaces from storage`);
            }
        }
        return [];
    }
    async deleteWorkspace(id) {
        const workspace = this._workspaces.get(id);
        if (workspace) {
            IndexedDBProvider.delete(id);
            this._workspaces.remove(id);
            this._storeWorkspaces(this._workspaces.list());
            if (workspace.blocksuiteWorkspace) {
                this._idbMap.delete(workspace.blocksuiteWorkspace);
            }
        }
        else {
            this._logger(`Failed to delete workspace ${id}`);
        }
    }
    async updateWorkspaceMeta(id, meta) {
        this._workspaces.update(id, meta);
        this._storeWorkspaces(this._workspaces.list());
    }
    async createWorkspace(meta) {
        const workspaceUnit = await createWorkspaceUnit({
            name: meta.name,
            id: uuidv4(),
            published: false,
            avatar: '',
            owner: undefined,
            syncMode: 'core',
            memberCount: 1,
            provider: this.id,
        });
        this._workspaces.add(workspaceUnit);
        this._storeWorkspaces(this._workspaces.list());
        return workspaceUnit;
    }
    async clear() {
        const workspaces = await this.loadWorkspaces();
        workspaces.forEach(ws => IndexedDBProvider.delete(ws.id));
        this._storeWorkspaces([]);
        this._workspaces.clear();
        this._idbMap.clear();
    }
}
