import { BaseProvider } from '../base.js';
import { Workspace as BlocksuiteWorkspace } from '@blocksuite/store';
import { storage } from './storage.js';
import assert from 'assert';
import { WebsocketProvider } from './sync.js';
// import { IndexedDBProvider } from '../local/indexeddb';
import { getApis } from './apis/index.js';
import { token } from './apis/token.js';
import { WebsocketClient } from './channel';
import { loadWorkspaceUnit, createWorkspaceUnit, migrateBlobDB, } from './utils.js';
import { WorkspaceUnit } from '../../workspace-unit.js';
import { createBlocksuiteWorkspace, applyUpdate } from '../../utils/index.js';
import { MessageCenter } from '../../message/index.js';
const { Y: { encodeStateAsUpdate }, } = BlocksuiteWorkspace;
export class AffineProvider extends BaseProvider {
    id = 'affine';
    _onTokenRefresh = undefined;
    _wsMap = new Map();
    _apis;
    _channel;
    // private _idbMap: Map<string, IndexedDBProvider> = new Map();
    constructor({ apis, ...params }) {
        super(params);
        this._apis = apis || getApis();
    }
    async init() {
        this._onTokenRefresh = () => {
            if (this._apis.token.refresh) {
                storage.setItem('token', this._apis.token.refresh);
            }
        };
        this._apis.token.onChange(this._onTokenRefresh);
        // initial login token
        if (this._apis.token.isExpired) {
            try {
                const refreshToken = storage.getItem('token');
                await this._apis.token.refreshToken(refreshToken);
                if (this._apis.token.refresh) {
                    storage.set('token', this._apis.token.refresh);
                }
                assert(this._apis.token.isLogin);
            }
            catch (_) {
                // this._logger('Authorization failed, fallback to local mode');
            }
        }
        else {
            storage.setItem('token', this._apis.token.refresh);
        }
        if (token.isLogin) {
            this._connectChannel();
        }
    }
    _connectChannel() {
        if (!this._channel) {
            this._channel = new WebsocketClient(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/api/global/sync/`, this._logger, {
                params: {
                    token: this._apis.token.refresh,
                },
            });
        }
        this._channel.on('message', (msg) => {
            this._handlerAffineListMessage(msg);
        });
    }
    async _handlerAffineListMessage({ ws_details, metadata, }) {
        this._logger('receive server message');
        const newlyCreatedWorkspaces = [];
        const currentWorkspaceIds = this._workspaces.list().map(w => w.id);
        const newlyRemovedWorkspacecIds = currentWorkspaceIds;
        for (const [id, detail] of Object.entries(ws_details)) {
            const { name, avatar } = metadata[id];
            /**
             * collect the workspaces that need to be removed in the context
             */
            const workspaceIndex = currentWorkspaceIds.indexOf(id);
            const ifWorkspaceExist = workspaceIndex !== -1;
            if (ifWorkspaceExist) {
                newlyRemovedWorkspacecIds.splice(workspaceIndex, 1);
            }
            /**
             * if workspace name is  not empty, it is  a valid workspace, so sync its state
             */
            if (name) {
                const workspace = {
                    name: name,
                    avatar,
                    owner: {
                        name: detail.owner.name,
                        id: detail.owner.id,
                        email: detail.owner.email,
                        avatar: detail.owner.avatar_url,
                    },
                    published: detail.public,
                    memberCount: detail.member_count,
                    provider: this.id,
                    syncMode: 'core',
                };
                if (this._workspaces.get(id)) {
                    // update workspaces
                    this._workspaces.update(id, workspace);
                }
                else {
                    const workspaceUnit = await loadWorkspaceUnit({ id, ...workspace }, this._apis);
                    newlyCreatedWorkspaces.push(workspaceUnit);
                }
            }
            else {
                console.log(`[log warn]  ${id} name is empty`);
            }
        }
        // sync newlyCreatedWorkspaces to context
        this._workspaces.add(newlyCreatedWorkspaces);
        // sync newlyRemoveWorkspaces to context
        this._workspaces.remove(newlyRemovedWorkspacecIds);
    }
    _getWebsocketProvider(workspace) {
        const { doc, room } = workspace;
        assert(room);
        assert(doc);
        let ws = this._wsMap.get(workspace);
        if (!ws) {
            const wsUrl = `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/api/sync/`;
            ws = new WebsocketProvider(wsUrl, room, doc, {
                params: { token: this._apis.token.refresh },
                // @ts-expect-error ignore the type
                awareness: workspace.awarenessStore.awareness,
            });
            workspace.awarenessStore.awareness.setLocalStateField('user', {
                name: token.user?.name ?? 'other',
                id: Number(token.user?.id ?? -1),
                color: '#ffa500',
            });
            this._wsMap.set(workspace, ws);
        }
        return ws;
    }
    async _applyCloudUpdates(blocksuiteWorkspace, published = false) {
        const { room: workspaceId } = blocksuiteWorkspace;
        assert(workspaceId, 'Blocksuite Workspace without room(workspaceId).');
        const updates = await this._apis.downloadWorkspace(workspaceId, published);
        await applyUpdate(blocksuiteWorkspace, new Uint8Array(updates));
    }
    async loadPublicWorkspace(blocksuiteWorkspace) {
        await this._applyCloudUpdates(blocksuiteWorkspace, true);
        return blocksuiteWorkspace;
    }
    async warpWorkspace(workspace) {
        await this._applyCloudUpdates(workspace);
        const { room } = workspace;
        assert(room);
        this.linkLocal(workspace);
        const ws = this._getWebsocketProvider(workspace);
        // close all websocket links
        Array.from(this._wsMap.entries()).forEach(([blocksuiteWorkspace, ws]) => {
            if (blocksuiteWorkspace !== workspace) {
                ws.disconnect();
            }
        });
        ws.connect();
        await new Promise((resolve, reject) => {
            // TODO: synced will also be triggered on reconnection after losing sync
            // There needs to be an event mechanism to emit the synchronization state to the upper layer
            assert(ws);
            ws.once('synced', () => resolve());
            ws.once('lost-connection', () => resolve());
            ws.once('connection-error', () => reject());
        });
        return workspace;
    }
    async loadWorkspaces() {
        if (!this._apis.token.isLogin) {
            return [];
        }
        const workspacesList = await this._apis.getWorkspaces();
        const workspaceUnits = await Promise.all(workspacesList.map(w => {
            return loadWorkspaceUnit({
                id: w.id,
                name: '',
                avatar: undefined,
                owner: undefined,
                published: w.public,
                memberCount: 1,
                provider: this.id,
                syncMode: 'core',
            }, this._apis);
        }));
        this._workspaces.add(workspaceUnits);
        return workspaceUnits;
    }
    async auth() {
        const refreshToken = await storage.getItem('token');
        if (refreshToken) {
            await this._apis.token.refreshToken(refreshToken);
            if (this._apis.token.isLogin && !this._apis.token.isExpired) {
                // login success
                return;
            }
        }
        const user = await this._apis.signInWithGoogle?.();
        if (!this._channel?.connected) {
            this._connectChannel();
        }
        if (!user) {
            this._sendMessage(MessageCenter.messageCode.loginError);
        }
    }
    async getUserInfo() {
        const user = this._apis.token.user;
        return user
            ? {
                id: user.id,
                name: user.name,
                avatar: user.avatar_url,
                email: user.email,
            }
            : undefined;
    }
    async deleteWorkspace(id) {
        await this.closeWorkspace(id);
        // IndexedDBProvider.delete(id);
        await this._apis.deleteWorkspace({ id });
        this._workspaces.remove(id);
    }
    async clear() {
        for (const w of this._workspaces.list()) {
            if (w.id) {
                try {
                    await this.deleteWorkspace(w.id);
                    this._workspaces.remove(w.id);
                }
                catch (e) {
                    this._logger('has a problem of delete workspace ', e);
                }
            }
        }
        this._workspaces.clear();
    }
    async closeWorkspace(id) {
        // const idb = this._idbMap.get(id);
        // idb?.destroy();
        const workspaceUnit = this._workspaces.get(id);
        const ws = workspaceUnit?.blocksuiteWorkspace
            ? this._wsMap.get(workspaceUnit?.blocksuiteWorkspace)
            : null;
        if (!ws) {
            console.error('close workspace websocket which not exist.');
        }
        ws?.disconnect();
    }
    async leaveWorkspace(id) {
        await this._apis.leaveWorkspace({ id });
    }
    async invite(id, email) {
        return await this._apis.inviteMember({ id, email });
    }
    async removeMember(permissionId) {
        return await this._apis.removeMember({ permissionId });
    }
    async linkLocal(workspace) {
        return workspace;
        // assert(workspace.room);
        // let idb = this._idbMap.get(workspace.room);
        // idb?.destroy();
        // idb = new IndexedDBProvider(workspace.room, workspace.doc);
        // this._idbMap.set(workspace.room, idb);
        // await idb.whenSynced;
        // this._logger('Local data loaded');
        // return workspace;
    }
    async createWorkspace(meta) {
        const workspaceUnitForUpload = await createWorkspaceUnit({
            id: '',
            name: meta.name,
            avatar: undefined,
            owner: await this.getUserInfo(),
            published: false,
            memberCount: 1,
            provider: this.id,
            syncMode: 'core',
        });
        const { id } = await this._apis.createWorkspace(new Blob([
            encodeStateAsUpdate(workspaceUnitForUpload.blocksuiteWorkspace.doc)
                .buffer,
        ]));
        const workspaceUnit = await createWorkspaceUnit({
            id,
            name: meta.name,
            avatar: undefined,
            owner: await this.getUserInfo(),
            published: false,
            memberCount: 1,
            provider: this.id,
            syncMode: 'core',
        });
        this._workspaces.add(workspaceUnit);
        return workspaceUnit;
    }
    async publish(id, isPublish) {
        await this._apis.updateWorkspace({ id, public: isPublish });
    }
    getToken() {
        return this._apis.token.token;
    }
    async getUserByEmail(workspace_id, email) {
        const users = await this._apis.getUserByEmail({ workspace_id, email });
        return users?.length
            ? {
                id: users[0].id,
                name: users[0].name,
                avatar: users[0].avatar_url,
                email: users[0].email,
            }
            : null;
    }
    async extendWorkspace(workspaceUnit) {
        const { id } = await this._apis.createWorkspace(new Blob([
            encodeStateAsUpdate(workspaceUnit.blocksuiteWorkspace.doc).buffer,
        ]));
        const newWorkspaceUnit = new WorkspaceUnit({
            id,
            name: workspaceUnit.name,
            avatar: undefined,
            owner: await this.getUserInfo(),
            published: false,
            memberCount: 1,
            provider: this.id,
            syncMode: 'core',
        });
        await migrateBlobDB(workspaceUnit.id, id);
        const blocksuiteWorkspace = createBlocksuiteWorkspace(id);
        assert(workspaceUnit.blocksuiteWorkspace);
        await applyUpdate(blocksuiteWorkspace, encodeStateAsUpdate(workspaceUnit.blocksuiteWorkspace.doc));
        newWorkspaceUnit.setBlocksuiteWorkspace(blocksuiteWorkspace);
        this._workspaces.add(newWorkspaceUnit);
        return newWorkspaceUnit;
    }
    async logout() {
        token.clear();
        this._channel?.disconnect();
        this._wsMap.forEach(ws => ws.disconnect());
        this._workspaces.clear(false);
        storage.removeItem('token');
    }
    async getWorkspaceMembers(id) {
        return this._apis.getWorkspaceMembers({ id });
    }
    async acceptInvitation(invitingCode) {
        return await this._apis.acceptInviting({ invitingCode });
    }
}
