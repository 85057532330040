// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
// Run `pnpm run download-resources` to regenerate.
// To overwrite this, please overwrite download.ts script.
import en from './en.json';
import zh_Hans from './zh-Hans.json';
export const LOCALES = [
    {
        id: 1000040001,
        name: 'English',
        tag: 'en',
        originalName: 'English',
        flagEmoji: '🇬🇧',
        base: true,
        completeRate: 1,
        res: en,
    },
    {
        id: 1000040004,
        name: 'Simplified Chinese',
        tag: 'zh-Hans',
        originalName: '简体中文',
        flagEmoji: '🇨🇳',
        base: false,
        completeRate: 1,
        res: zh_Hans,
    },
];
