import { getAuthorizer } from './token.js';
import * as user from './user.js';
import * as workspace from './workspace.js';
import { token } from './token.js';
export const getApis = () => {
    const [signInWithGoogle, onAuthStateChanged] = getAuthorizer();
    return {
        ...user,
        ...workspace,
        signInWithGoogle,
        onAuthStateChanged,
        token,
    };
};
export { WorkspaceType } from './workspace.js';
