export class WorkspaceUnit {
    id;
    name;
    avatar;
    owner;
    published;
    memberCount;
    provider;
    syncMode = 'core';
    _blocksuiteWorkspace;
    constructor(params) {
        this.id = params.id;
        this.update(params);
    }
    get isPublish() {
        console.error('Suggest changing to published');
        return this.published;
    }
    get isLocal() {
        console.error('Suggest changing to syncMode');
        return this.syncMode === 'all';
    }
    get blocksuiteWorkspace() {
        return this._blocksuiteWorkspace;
    }
    setBlocksuiteWorkspace(blocksuiteWorkspace) {
        if (blocksuiteWorkspace && blocksuiteWorkspace.room !== this.id) {
            throw new Error('Workspace id inconsistent.');
        }
        this._blocksuiteWorkspace = blocksuiteWorkspace;
    }
    update(params) {
        Object.assign(this, params);
    }
    toJSON() {
        return {
            id: this.id,
            name: this.name,
            avatar: this.avatar,
            owner: this.owner,
            published: this.published,
            memberCount: this.memberCount,
            provider: this.provider,
            syncMode: this.syncMode,
        };
    }
}
